<style scoped>
  .menu-list li ul {
    margin: 0;
  }
</style>

<template>
  <li
    :key="node.id"
  >
    <a
      :class="{'is-active': Boolean(node.active)}"
      @click="selectNode"
    >
      <template v-if="node === null">
        Root Node
      </template>
      <template v-else>
        <span>{{ node.name }} </span>
        <span class="is-italic">{{ node.id }} </span>
      </template>
      <b-icon
        v-if="node.id !== null"
        icon="delete"
        class="is-pulled-right"
        @click.native.stop="removeNode"
      />
    </a>

    <ul
      v-if="childNodes.length"
      class="menu-list"
    >
      <vehicle-node
        v-for="child in childNodes"
        :key="child.id"
        :node="child"
        :nodes="nodes"
        @select="select"
        @remove="remove"
      />
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import VehicleNode from './VehicleNode'

export default {
  name: 'VehicleNode',
  components: {
    VehicleNode
  },
  props: {
    node: {
      required: true
    },
    nodes: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    childNodes () {
      return this.children('nodes')
    }
  },
  methods: {
    children (items) {
      if (this.node.id === null) {
        return _.filter(this[items], item => {
          return item.parent === null
        })
      } else {
        return _.filter(this[items], item => {
          return item.parent ? item.parent.id === this.node.id : false
        })
      }
    },
    selectNode () {
      if (!this.disabled) {
        this.$emit('select', this.node)
      }
    },
    removeNode () {
      if (!this.disabled) {
        this.$emit('remove', this.node)
      }
    },
    select (parent) {
      this.$emit('select', parent)
    },
    remove (parent) {
      this.$emit('remove', parent)
    }
  }
}
</script>
